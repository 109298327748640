import React from "react";
import { IoCloseSharp } from "react-icons/io5";
import { authAxios } from "../../config/config";
import { toast } from "react-toastify";

const ViewUpdateStatus = ({ setShowUpdateStatusModal, selectedUserData, setLoading, fetchtask, inprogressKey,fetchPendingPayouts }) => {

  const handleClose = () => {
    setShowUpdateStatusModal(false);
  };
  //   const { _id: taskId, user: { _id: directorId } } = selectedUserData;
  //   console.log("Task ID:", taskId, "Director ID:", directorId);

  // update open status to close 
  const UpdateStatusFunction = async () => {
    try {
      setLoading(true);
      const { _id: taskId, user: { _id: directorId } } = selectedUserData;
      const response = await authAxios().put(`/task/task-status/${taskId}/${directorId}`);
      setLoading(false);
      if (response?.data?.status === 1) {
        fetchtask();
        toast.success("Task status updated successfully.");
        handleClose();
      } else {
        toast.error("Failed to update task status.");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating task status:", error);
      toast.error(`Error updating task status: ${error.message}`);
    }
  };

  //  update in-progress status to complete

  const UpdateStatusInprogressFunction = async () => {
    try {
      setLoading(true);
      const { _id: taskId, } = selectedUserData;
      const response = await authAxios().put(`/task/task-Completed/${taskId}`);
      setLoading(false);
      if (response?.data?.status === 1) {
        fetchtask();
        fetchPendingPayouts();
        toast.success("Task status updated successfully.");
        handleClose();
      } else {
        toast.error("Failed to update task status.");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating task status:", error);
      toast.error(`Error updating task status: ${error.message}`);
    }
  };


  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-10 w-full overflow-y-auto top-[0]">
        <div className="absolute transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-[95%] max-w-lg top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
          <div className="modal-content p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-bold"></h2>
              <button onClick={handleClose} className="text-gray-500 hover:text-gray-800">
                <IoCloseSharp size={24} />
              </button>
            </div>
            <div className="flex flex-col items-center">
              {inprogressKey === "inprogress" ?
                <p className="text-center mb-4">
                  You are marking this job as completed. Are you sure ? 
                  {/* Are you sure you want to proceed with completing this task? Please confirm if you'd like to continue with the completion process. */}
                </p>
                :
                <p className="text-center mb-4">
                  You are closing this job. Are you sure you want to continue?
                </p>
              }
              <div className="flex space-x-4">
                <button
                  onClick={inprogressKey === "inprogress" ? UpdateStatusInprogressFunction : UpdateStatusFunction}
                  className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition"
                >
                  YES
                </button>
                <button
                  onClick={handleClose}
                  className="bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-700 transition"
                >
                  NO
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewUpdateStatus;
