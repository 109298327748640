import React from "react";
import { GrFormPreviousLink, GrFormNextLink } from "react-icons/gr";

const Pagination = ({ postsPerPage, totalPosts, currentPage, paginate ,length}) => {
  const pageNumbers = [];

  for (let i = 0; i < Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i + 1);
  }

  return (
    <>
      {pageNumbers && pageNumbers.length > 0 && (
        <div className="pt-[20px] pb-[20px] bg-white pagination--container">
          <div className="flex items-center justify-center sm:flex-row flex-col gap-[10px]">
            <div className="flex items-center sm:justify-end justify-center gap-4 w-full">
              <button
                disabled={currentPage === 1}
                onClick={() => paginate(currentPage - 1)}
                className={`flex items-center sm:min-w-[150px] w-[100%] sm:w-auto border border-textcolor justify-center bg-white text-textcolor py-[8px] sm:px-[20px] px-[10px] rounded-[5px] duration-[0.3s] hover:bg-buttonbg hover:text-white sm:h-[45px] h-[35px] font-[600] sm:text-[16px] text-[14px] ${currentPage === 1 ? "opacity-[0.5]" : "opacity-[1]"
                  }`}
                type="button"
              >
                <GrFormPreviousLink className="w-4 h-4" />
                Previous
              </button>
              <button
                disabled={currentPage === Math.ceil(totalPosts / postsPerPage)}
                onClick={() => paginate(currentPage + 1)}
                className={`flex items-center sm:min-w-[150px] w-[100%] sm:w-auto border border-textcolor justify-center bg-white text-textcolor py-[8px] sm:px-[20px] px-[10px] rounded-[5px] sm:text-[16px] text-[14px] duration-[0.3s] hover:bg-buttonbg hover:text-white sm:h-[45px] h-[35px] font-[600] ${currentPage === Math.ceil(totalPosts / postsPerPage)
                    ? "opacity-[0.5]"
                    : "opacity-[1]"
                  }`}
                type="button"
              >
                Next
                <GrFormNextLink className="w-4 h-4" />
              </button>
            </div>

            <div className="flex items-center justify-end gap-[10px] sm:w-[80%] w-[100%] sm:text-[16px] text-[14px]">
             <input
                type="number"
                value={currentPage}
                disabled
              
                className={`arrow--none sm:w-[48px] sm:h-[38px] w-[35px] h-[35px] border border-textcolor rounded-[5px] flex items-center justify-center p-[5px] text-textcolor bg-white text-center`}
              />
              of {pageNumbers?.length} Pages
              
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Pagination;
