import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import IsLoadingHOC from "./IsLoadingHOC";
import { authAxios } from "../config/config";
import { toast } from "react-toastify";
import { FaEye } from "react-icons/fa";
import ViewTaskDetail from "./ViewTaskDetail";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { savePaymentInvoice, saveSubscriptionInvoice } from "../Redux/Reducers/appSlice";
import ChartOne from "./ChartOne";
import { BudgetFormat, displayBudget, payloadBudget, setFormatDate, setReportFormatDate } from "../Helper/helper";
import ChartOneOne from "./ChartOneOne";

const RecentPayments = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState({
    series: [
      {
        name: "Sales",
        data: [44, 55, 41, 67, 22, 43, 65],
      },
      {
        name: "Revenue",
        data: [13, 23, 20, 8, 13, 27, 15],
      },
    ],
  });

  const options = {
    colors: ["#3C50E0", "#80CAEE"],
    chart: {
      fontFamily: "Satoshi, sans-serif",
      type: "bar",
      height: 335,
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },

    responsive: [
      {
        breakpoint: 1536,
        options: {
          plotOptions: {
            bar: {
              borderRadius: 0,
              columnWidth: "25%",
            },
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 0,
        columnWidth: "25%",
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "last",
      },
    },
    dataLabels: {
      enabled: false,
    },

    xaxis: {
      categories: ["M", "T", "W", "T", "F", "S", "S"],
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      fontFamily: "Satoshi",
      fontWeight: 500,
      fontSize: "14px",
      markers: {
        radius: 99,
      },
    },
    fill: {
      opacity: 1,
    },
  };

  const { setLoading } = props;

  const [paymentDetails, setpaymentDetails] = useState([]);
  const [subscriptionDetails, setSubscriptionDetails] = useState([]);
  const [paymentDetailsGraph,setpaymentDetailsGraph]=useState([])
  const [subscriptionDetailsGraph,setSubscriptionDetailsGraph]=useState([])
  const [graphData,setGraphData]=useState([])
  const [graphDataSub,setGraphDataSub]=useState([])
  const [year,setYear]=useState("2024")
  const [showTaskDetail, setshowTaskDetail] = useState({
    model: false,
    data: [],
  });


  const handleGraphPaymentsDetails=async()=>{
    setLoading(true);
    try {
      const response = await authAxios().get(
        `/dashboard/payment-graph?year=${year}`
      );
      setLoading(false);
      const resData = response?.data;
      console.log("graph details",resData?.data)
      if (resData?.status === 1) {
        setpaymentDetailsGraph([resData?.data[0]?.totalAmount/100,resData?.data[1]?.totalAmount/100,resData?.data[2]?.totalAmount/100,resData?.data[3]?.totalAmount/100,resData?.data[4]?.totalAmount/100,resData?.data[5]?.totalAmount/100,
          resData?.data[6]?.totalAmount/100,resData?.data[7]?.totalAmount/100,resData?.data[8]?.totalAmount/100,resData?.data[9]?.totalAmount/100,resData?.data[10]?.totalAmount/100,
          resData?.data[11]?.totalAmount/100]);
      } else {
        toast.error(resData?.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }

  }

  const handleGraphSubscriptionsDetails=async()=>{
    setLoading(true);
    try {
      const response = await authAxios().get(
        `/dashboard/subscription-graph?year=${year}`
      );
      setLoading(false);
      const resData = response?.data;
      console.log("subscription details",resData?.data)
      if (resData?.status === 1) {
        setSubscriptionDetailsGraph([resData?.data[0]?.totalAmount/100,resData?.data[1]?.totalAmount/100,resData?.data[2]?.totalAmount/100,resData?.data[3]?.totalAmount/100,resData?.data[4]?.totalAmount/100,resData?.data[5]?.totalAmount/100,
          resData?.data[6]?.totalAmount/100,resData?.data[7]?.totalAmount/100,resData?.data[8]?.totalAmount/100,resData?.data[9]?.totalAmount/100,resData?.data[10]?.totalAmount/100,
          resData?.data[11]?.totalAmount/100]);
      } else {
        toast.error(resData?.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }

  }
  const handleFetchPaymentDetails = async () => {
    setLoading(true);
    try {
      const response = await authAxios().get(
        `/stripe/list-stripe-payment-details?page=${1}&limit=${5}`
      );
      setLoading(false);
      const resData = response?.data;
      console.log("payment data",resData)
      if (resData?.status === 1) {
      
        setpaymentDetails(resData?.data?.payments);
      
      } else {
        toast.error(resData?.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  console.log("payment details",paymentDetails)

  const handleFetchSubscriptionDetail = async () => {
    setLoading(true);
    try {
      const response = await authAxios().get(
        `/stripe/list-stripe-subscription-details?page=${1}&limit=${5}`
      );
      setLoading(false);
      const resData = response?.data;
      console.log("subscription data",resData)
      if (resData?.status === 1) {
        setSubscriptionDetails(resData?.data.subscriptions);
      } else {
        toast.error(resData?.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleView = (item) => {
    setshowTaskDetail((prev) => ({
      ...prev,
      model: true,
      data: item,
    }));
  };

  const handlechangePayment = (e) => {
    if (e.target.value == "normal") {
      handleFetchPaymentDetails();
      setSubscriptionDetails([]);
      handleGraphPaymentsDetails();
    } else if (e.target.value == "subscription") {
      handleFetchSubscriptionDetail();
      handleGraphSubscriptionsDetails();
     
      setpaymentDetails([]);  
    }
  };

  const handlechangeYear=(e)=> {
   setYear(e.target.value)

  }

  useEffect(() => {
   
    
    handleGraphPaymentsDetails();
    handleGraphSubscriptionsDetails();
    handleFetchPaymentDetails();
    
  }, []);


  useEffect(() => {
   
    
    handleGraphPaymentsDetails();
    handleGraphSubscriptionsDetails();
   
    
  }, [year]);



  useEffect(() => {
    // let result=paymentDetailsGraph?.map(amount=>amount.totalAmount)
    // console.log("result",result)
    
    setGraphData(paymentDetailsGraph)
  }, [paymentDetailsGraph]);

  useEffect(() => {
    // let result=paymentDetailsGraph?.map(amount=>amount.totalAmount)
    // console.log("result",result)
    
    setGraphDataSub(subscriptionDetailsGraph)
  }, [subscriptionDetailsGraph]);


  const showPaymentInvoice = (data) => {
    const FinalBid=payloadBudget(`${data.task?.finalPrice||data.task?.budget}`)
    sessionStorage.setItem("FinalBid", FinalBid);
    dispatch(savePaymentInvoice(data))
    navigate('/payment-invoice')
  }

  const showSubscriptionInvoice = (data) => {
    dispatch(saveSubscriptionInvoice(data))
    navigate('/subscription-invoice')
  }

  return (
    <div className="col-span-12 rounded-[10px] border border-stroke bg-white p-5 shadow-default dark:border-strokedark dark:bg-boxdark xl:col-span-6">
      <div className="justify-between gap-4 sm:flex">
        <div>
        <select className="focus:outline-none focus:ring-0" onChange={handlechangeYear}>
              {/* <option value="">Select Year</option> */}
              <option value="2024">2024</option>
              <option value="2025">2025</option>
            </select>

        </div>
        <div>
          <div className="relative  inline-block">
            <select className="focus:outline-none focus:ring-0" onChange={handlechangePayment}>
              <option value="normal">Payment Details</option>
              <option value="subscription">Subscription Details</option>
            </select>
          </div>
        </div>
      </div>

      
     

      {paymentDetails && paymentDetails?.length > 0 && (
        <div className="w-[100%] mx-auto">
           <div className="text-xl  font-semibold text-black dark:text-black">
      <h5 className="pb-[10px] mt-2">Payment Analytics </h5>
      <ChartOne graphData={graphData}></ChartOne>

      </div>
          <div className="flex mt-[20px] mb-[0px] gap-[20px] items-center">
            <h1 className="text-[clamp(20px,2vw,35px)] leading-none font-semibold">
              Recent Payments
            </h1>
          </div>
          <div className="relative mt-[10px] overflow-x-auto px-[20px] py-[15px] bg-white rounded-t-[10px]  over--scroller area--table">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-lightcolor border-b border-textcolor">
                <tr>
                  <th className="p-4 font-[400]">Amount</th>

                  <th className="p-4 font-[400]">View Invoice</th>

                  <th className="p-4 font-[400]">Task Details</th>
                </tr>
              </thead>
              <tbody>
                {paymentDetails && paymentDetails.length>0 &&
                  paymentDetails.map((item, index) => (
                    <tr
                      key={index}
                      className="bg-white border-b hover:bg-gray-50"
                    >
                      <td className="px-5 py-5 border-b border-gray-200 text-sm min-w-[80px]">
                        <p className="text-gray-900 whitespace-no-wrap">
                          ${item?.payment.amount / 100}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 text-sm min-w-[80px]" >
                        <p
                          onClick={() => showPaymentInvoice(item)}
                          // onClick={() => window.open(item.stripeInvoiceUrl)}
                          className="text-gray-900 whitespace-no-wrap cursor-pointer">
                          View Invoice
                        </p>
                      </td>
                      <td
                        className="px-5 py-5 border-b border-gray-200  min-w-[80px] text-xl cursor-pointer"
                      >
                        <FaEye onClick={() => handleView(item.task)} />
                      </td>
                    </tr>
                  ))  }
              </tbody>
            </table>
          </div>

          {showTaskDetail.model && (
            <ViewTaskDetail
              showTaskDetail={showTaskDetail}
              setshowTaskDetail={setshowTaskDetail}
            />
          )}
          <Link to="/payment-details" className="float-right bg-black text-white px-4 py-2 rounded-[50px]">View All</Link>

        </div>
      )}

      {subscriptionDetails && subscriptionDetails.length > 0 && (
        <div className="w-[100%] mx-auto">
          <div className="text-xl font-semibold text-black dark:text-black">
      Subscription Analytics
      <ChartOneOne graphDataSub={graphDataSub}></ChartOneOne>

      </div>
          <div className="flex mb-[30px] gap-[20px] items-center">
            <h1 className="text-[clamp(20px,2vw,35px)] leading-none font-semibold">
              Subscription Details
            </h1>
          </div>
          <div className="relative mt-[10px] overflow-x-auto px-[20px] py-[15px] bg-white rounded-t-[10px]  over--scroller area--table">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-lightcolor border-b border-textcolor">
                <tr>
                  <th className="p-4 font-[400]">Amount</th>

                  <th className="p-4 font-[400]">View Invoice</th>

                  <th className="p-4 font-[400]">Task Details</th>
                </tr>
              </thead>

              <tbody>
                {subscriptionDetails &&
                  subscriptionDetails.map((item, index) => (
                    <tr
                      key={index}
                      className="bg-white border-b hover:bg-gray-50"
                    >
                      <td className="px-5 py-5 border-b border-gray-200 text-sm min-w-[80px]">
                        <p className="text-gray-900 whitespace-no-wrap">
                          ${item?.plan?.amount / 100}
                        </p>
                      </td>
                      <td
                        className="px-5 py-5 border-b border-gray-200 text-sm min-w-[80px]"
                      >
                        <p
                          onClick={() => showSubscriptionInvoice(item)}
                          // onClick={() => window.open(item.latestInvoice.hostedInvoiceUrl)} 
                          className="text-gray-900 whitespace-no-wrap cursor-pointer">
                          View Invoice
                        </p>
                      </td>
                      <td
                        onClick={() => handleView(item.task)}
                        className="px-5 py-5 border-b border-gray-200 min-w-[80px] text-xl cursor-pointer"
                      >
                        <FaEye />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          {showTaskDetail.model && (
            <ViewTaskDetail
              showTaskDetail={showTaskDetail}
              setshowTaskDetail={setshowTaskDetail}
            />
          )}
          <Link to="/subscription-details" className="float-right bg-black text-white px-4 py-2 rounded-[50px]">View All</Link>
        </div>
      )}
    </div>
  );
};

export default IsLoadingHOC(RecentPayments);
