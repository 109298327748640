import { XCircleIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import Creatable, { useCreatable } from "react-select/creatable";
import { authAxios } from "../../config/config";
import { toast } from "react-toastify";
import IsLoadingHOC from "../../Common/IsLoadingHOC";

const PopupNewsLetter = ({ setLoading }) => {
  const [formData, setformData] = useState({
    email: [],
    subject: "",
    description: "",
  });
  const [Users, setUsers] = useState([]);

  const handleEmail = (newValue) => {
    if (newValue) {
      setformData((prev) => ({
        ...prev,
        email: newValue,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const allemails = formData.email.map((item) => item.label);
    const payload = {
      recipients: allemails,
      subject: formData.subject,
      text: formData.description,
    };

    handleSendNewsLetter(payload);
  };

  const fetchallUsers = async () => {
    setLoading(true);
    await authAxios()
      .get(`/users/get-all-users/ALL`)
      .then((response) => {
        const resData = response?.data;

        setLoading(false);

        if (resData?.status == 1) {
          const arr = [];

          resData.data.map((item) => {
            arr.push({ label: item.email, value: item.email });
          });
          console.log("arr", arr);
          setUsers(arr);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const handleSendNewsLetter = async (payload) => {
    setLoading(true);
    await authAxios()
      .post("/newsletter/send-news-letter", payload)
      .then((response) => {
        const resData = response?.data;
        setLoading(false);
        if (resData?.status == 1) {
          setformData((prev) => ({
            ...prev,
            email: [],
            subject: "",
            description: "",
          }));
          toast.success(resData?.message);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  useEffect(() => {
    fetchallUsers();
  }, []);
  return (
    <>
      <div className="px-[50px] py-[20px] bg-lightgray area--page pb-0">
        <div className="w-[100%] mx-auto pb-[65px]">
          <div className="flex mb-[30px] gap-[20px] gap-y-[10px] md:items-center w-full md:justify-between md:flex-row flex-col-reverse "></div>

          <div className="relative mt-[10px] overflow-x-auto px-[20px] py-[15px] bg-white rounded-t-[10px] h-[100vh] over--scroller area--table">
            <form onSubmit={handleSubmit}>
              <div class="relative mb-6">
                <label class="flex  items-center mb-2 text-gray-600 text-sm font-medium">
                  {" "}
                  Enter Email{" "}
                  <svg
                    width="7"
                    height="7"
                    class="ml-1"
                    viewBox="0 0 7 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.11222 6.04545L3.20668 3.94744L1.43679 5.08594L0.894886 4.14134L2.77415 3.18182L0.894886 2.2223L1.43679 1.2777L3.20668 2.41619L3.11222 0.318182H4.19105L4.09659 2.41619L5.86648 1.2777L6.40838 2.2223L4.52912 3.18182L6.40838 4.14134L5.86648 5.08594L4.09659 3.94744L4.19105 6.04545H3.11222Z"
                      fill="#EF4444"
                    />
                  </svg>
                </label>
                <Creatable
                  type="email"
                  onChange={handleEmail}
                  isMulti
                  class="block w-full h-11 px-5 py-2.5 leading-7 text-base font-normal shadow-xs text-gray-900 bg-transparent border border-gray-300 rounded-full placeholder-gray-400 focus:outline-none "
                  placeholder="Enter Email"
                  required
                  value={formData?.email}
                  options={Users}
                />
              </div>

              <div class="relative mb-6">
                <label class="flex  items-center mb-2 text-gray-600 text-sm font-medium">
                  {" "}
                  Subject{" "}
                  <svg
                    width="7"
                    height="7"
                    class="ml-1"
                    viewBox="0 0 7 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.11222 6.04545L3.20668 3.94744L1.43679 5.08594L0.894886 4.14134L2.77415 3.18182L0.894886 2.2223L1.43679 1.2777L3.20668 2.41619L3.11222 0.318182H4.19105L4.09659 2.41619L5.86648 1.2777L6.40838 2.2223L4.52912 3.18182L6.40838 4.14134L5.86648 5.08594L4.09659 3.94744L4.19105 6.04545H3.11222Z"
                      fill="#EF4444"
                    />
                  </svg>
                </label>
                <input
                  type="text"
                  id="default-search"
                  class="block w-full h-11 px-5 py-2.5 leading-7 text-base font-normal shadow-xs text-gray-900 bg-transparent border border-gray-300 rounded-full placeholder-gray-400 focus:outline-none "
                  placeholder="name@pagedone.com"
                  required
                  value={formData?.subject}
                  onChange={(e) =>
                    setformData((prev) => ({
                      ...prev,
                      subject: e.target.value,
                    }))
                  }
                />
              </div>

              <div class="relative mb-6">
                <label class="flex items-center mb-2 text-gray-600 text-sm font-medium">
                  {" "}
                  Message{" "}
                  <svg
                    width="7"
                    height="7"
                    class="ml-1"
                    viewBox="0 0 7 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.11222 6.04545L3.20668 3.94744L1.43679 5.08594L0.894886 4.14134L2.77415 3.18182L0.894886 2.2223L1.43679 1.2777L3.20668 2.41619L3.11222 0.318182H4.19105L4.09659 2.41619L5.86648 1.2777L6.40838 2.2223L4.52912 3.18182L6.40838 4.14134L5.86648 5.08594L4.09659 3.94744L4.19105 6.04545H3.11222Z"
                      fill="#EF4444"
                    ></path>
                  </svg>
                </label>
                <div class="flex">
                  <div class="relative w-full">
                    <textarea
                      class="block w-full h-40 px-4 py-2.5 text-base leading-7 font-normal shadow-xs text-gray-900 bg-transparent border border-gray-300 rounded-2xl placeholder-gray-400 focus:outline-none resize-none"
                      placeholder="Enter Description"
                      value={formData?.description}
                      onChange={(e) =>
                        setformData((prev) => ({
                          ...prev,
                          description: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
              </div>

              <button
                type="submit"
                class="w-52 h-12 bg-black text-white   transition-all duration-700 rounded-full shadow-xs text-base font-semibold leading-6"
              >
                Send NewsLetter
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default IsLoadingHOC(PopupNewsLetter);
