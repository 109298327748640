import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { PDFDownloadLink } from "@react-pdf/renderer";
import PaymentInvoicePdf from '../Invoice/PaymentInvoicePdf';
import { IoCloudDownloadOutline } from "react-icons/io5";
import { payloadBudget } from '../../Helper/helper';


const PaymentInvoice = () => {
    const { paymentInvoice } = useSelector(state => state.app)
    const FinalBid=sessionStorage.getItem("FinalBid")


    return (
        <div className="bg-gray-100 min-h-screen flex justify-center items-center">
            <div className="bg-white pb-[50px] rounded-lg shadow-md w-[95%] max-w-4xl">
                <div className="bg-black rounded-t-lg">
                    <div className="flex justify-between items-center p-4">
                        <a target="_blank" rel="noreferrer">
                            <img
                                alt="Logo"
                                src="https://stripe-images.s3.amazonaws.com/emails/acct_1OhFFXF3HwezfEYa/5/twelve_degree_icon@2x.png"
                                className="w-10"
                            />
                        </a>
                    </div>
                </div>
                <div className="text-center p-6 pt-[40px] pb-[20px]">
                    <h2 className="text-2xl font-bold text-gray-700">Receipt from SIDEHUSTL</h2>
                    {/* <p className="text-lg text-gray-500 mt-2">Receipt/199-2720</p> */}
                </div>
                <div className="px-6 py-4 pb-[30px]">
                    <div className="flex flex-col sm:flex-row gap-y-3 justify-between text-sm mb-2">
                        <div className='flex flex-col'>
                        <span className='font-semibold'>Amount paid</span>
                        <span>${(paymentInvoice?.payment?.amount / 100)?.toFixed(2)}</span>
                        </div>

                        <div className='flex flex-col'>
                        <span className='font-semibold'>Date</span>
                        {paymentInvoice?.payment?.created &&
                            <span>{moment.unix(paymentInvoice?.payment?.created)?.format('MMM D, YYYY')}</span>
                        }
                        </div>

                        <div className='flex flex-col'>
                        <span className='font-semibold'>Card Details</span>
                        
                        xxxx-xxxx-xxxx-{paymentInvoice?.payment?.payment_method_details?.card?.last4}
                        </div>
                    </div>
                    <div className="flex justify-between text-base text-gray-700">
                        
                        
                        <span className='text-[15px] font-bold uppercase'>
                        </span>
                    </div>
                </div>
                <div className="px-6 py-4 pb-[30px]">
                    <h3 className="text-xs font-bold uppercase text-gray-500 pb-[10px]">Summary</h3>
                    <div className="bg-gray-100 rounded-lg mt-2 p-4">
                        <div className="flex flex-col sm:flex-row justify-between text-base text-gray-700">
                            <span>Payment to SIDEHUSTL</span>
                            <span>${FinalBid}</span>
                        </div>
                        <div className="flex flex-col sm:flex-row justify-between text-base text-gray-700">
                            <span>Service fee</span>
                            <span>${payloadBudget(`${ (0.18) * FinalBid}`)}</span>
                        </div>
                        <div className="flex flex-col sm:flex-row justify-between text-base text-gray-700 mt-2 font-bold">
                            <span>Amount charged</span>
                            <span>${(paymentInvoice?.payment?.amount_captured / 100)?.toFixed(2)}</span>
                        </div>
                    </div>
                </div>
                <div className="px-6 py-4 text-gray-700 pb-[20px]">
                    <p className='text-sm'>
                        If you have any questions, contact us at{' '}
                        <a href="mailto:admin@sidehustl.ca" className="text-black font-bold">
                            admin@sidehustl.ca
                        </a>{' '}
                        {/* or call us at{' '}
                        <a href="tel:+13062611157" className="text-blue-600 font-bold">
                            +1 306-261-1157
                        </a> */}
                    </p>
                </div>
                <div className="mb-4 mt-3 flex justify-center">
                    <PDFDownloadLink
                        document={
                            <PaymentInvoicePdf
                                data={paymentInvoice}
                                FinalBid={FinalBid}
                            />
                        }
                        fileName="Payment_Invoice"
                    >
                        {({ blob, url, loading, error }) =>
                            loading ? (
                                "Loading..."
                            ) : (
                                <button className="flex items-center text-sm leading-5 transition-colors duration-150 font-medium focus:outline-none px-5 py-2 rounded-md text-white bg-black border border-transparent active:bg-emerald-600 hover:bg-gray-800  w-auto cursor-pointer">
                                    Download Invoice
                                    <span className="ml-2 text-base">
                                        <IoCloudDownloadOutline />
                                    </span>
                                </button>
                            )
                        }
                    </PDFDownloadLink>
                </div>
            </div>
        </div>
    );
};

export default PaymentInvoice;