import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const options = {
  chart: {
    type: 'bar',
    height: 350
  },
  plotOptions: {
    bar: {
      borderRadius: 4,
      borderRadiusApplication: 'end',
      horizontal:false
    }
  },
  dataLabels: {
    enabled: false
  },
  xaxis: {
    categories: ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"
    ],
    title:{text:"Months"}
  },
  yaxis:{ title: {
    text: "Payments ($)",
    }}

};

const ChartOne = ({graphData}) => {
  // console.log(graphData,"Graphdata")

  //   const [showGraph,setShowGraph]=useState([])
  // const [series, setSeries] = useState([]);


  // useEffect(()=>{
    
  //   setSeries(graphData) 
  //  },[])
  const series = [
    {
      name: "Payments($)",
      data: graphData,
    },
  ];

  return (
    <div className="col-span-12 rounded-sm border border-stroke bg-white px-5 pt-7.5 pb-5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:col-span-8">
    <div className="flex flex-wrap items-start justify-between gap-3 sm:flex-nowrap">
      
      
    </div>

    <div>
      <div id="chartOne" className="-ml-5">
        <Chart
          options={options}
          series={series}
          type="bar"
          height={350}
        />
      </div>
    </div>
  </div>
  );
};

export default ChartOne;
