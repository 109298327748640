import React, { useEffect, useState } from "react";
import { authAxios } from "../config/config";
import { useParams,useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { removeDuplicates,setReportFormatDate } from "../Helper/helper";
import { FaAngleDown, FaChevronLeft } from "react-icons/fa6";

const Profile = () => {
  const params = useParams();
  const { id } = params;
  const AllUserCurrentPage = localStorage.getItem("AllUserCurrentPage")

  const [userDetail, setuserDetail] = useState([]);
  const [Comments, setComments] = useState([]);
  const [allSerivceCategory, setallSerivceCategory] = useState({
    serviceName: [],
    category: [],
  });
   const navigate = useNavigate();
  const handleClickNavigate = () => {
    navigate('/all-users');  
    localStorage.setItem("backProfile","true")
  };
  const fetchUserDeatil = async () => {
    await authAxios()
      .get(`/users/get-specific-user/${id}`)
      .then((response) => {
        const resData = response.data;
        if (resData.status == 1) {
          setuserDetail(resData.data);
          handleSetServiceandCategory(resData.data);
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const handleSetServiceandCategory = (userDetails) => {
    const ServiceArr = userDetails?.hustlerDetails?.services?.map(
      (item) => item?.serviceName
    );

    const NewArr = [];

    userDetails?.hustlerDetails?.services?.map((item) => {
      item?.category?.map((category) => {
        NewArr.push(category?.label);
      });
    });

    setallSerivceCategory((prev) => ({
      ...prev,
      serviceName: removeDuplicates(ServiceArr),
      category: removeDuplicates(NewArr),
    }));
  };

  const fetchreview = async () => {
    await authAxios()
      .get(`/review/specific-user-reviews/${id}`)
      .then((response) => {
        const resData = response.data;
        if (resData.status == 1) {
          setComments(resData.data);
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  

 

  useEffect(() => {
    fetchUserDeatil();
    fetchreview();
  }, []);

  return (
    <>
      {userDetail?.userType == "DIRECTOR" ? 
      (
        <div className="pt-5 px-3">
          <div className="bg-white md:mx-auto rounded shadow-xl w-full md:w-1/2 overflow-hidden">
		  <button onClick={handleClickNavigate} className="btn btn-primary w-[20%] p-3 text-left text-white absolute">
			<FaChevronLeft className="mt-1 float-left" />Back </button>
            <div className="h-[100px] bg-gradient-to-r from-[#000000] to-[#585858]"></div>
            <div className="px-5 py-2 flex flex-col gap-3 pb-6">
              <div className="h-[90px] shadow-md w-[90px] rounded-full border-4 overflow-hidden -mt-14 border-white">
                <img
                  src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${userDetail?.profilePic}`}
                  className="w-full h-full rounded-full object-center object-cover"
                />
              </div>
              <div className="">
                <h3 className="text-xl text-slate-900 relative font-bold leading-6">
                  {userDetail.fname} {userDetail.lname}
                  <div className="text-sm"><h5>Joining Date: {setReportFormatDate(userDetail.createdAt)}</h5></div>
                
                </h3>
              </div>
              
              

              <p className="text-sm text-stone-500">
                {userDetail?.description}
              </p>

              <div className="profile--info--work">
                <div className="flex items-start justify-center flex-col profile-info-item">
                  <img src="/assets/images/interestshare.png" />
                  <h5>Total Jobs</h5>
                  <p>{userDetail?.directorDetails?.totalTask}</p>
                </div>
                <div className="flex items-start justify-center flex-col profile-info-item">
                  <img src="/assets/images/completetask.png" />
                  <h5> Completed Jobs </h5>
                  <p>{userDetail?.directorDetails?.completedTask}</p>
                </div>
                <div className="flex items-start justify-center flex-col profile-info-item">
                  <img src="/assets/images/pendingtask.png" />
                  <h5> InProgress Jobs</h5>
                  <p>{userDetail?.directorDetails?.inProgressTask}</p>
                </div>
                <div className="flex items-start justify-center flex-col profile-info-item">
                  <img src="/assets/images/cancelled.png" />
                  <h5> Closed Jobs</h5>
                  <p>{userDetail?.directorDetails?.closedTask}</p>
                </div>

                <div className="flex items-start justify-center flex-col profile-info-item">
                  <h5>Behavior</h5>
                  <div className="flex items-center">
                    <p>
                      {userDetail?.directorDetails?.behaviorAvg?.toFixed(1)}
                    </p>
                    <img
                      src="/assets/images/hustler-star.png"
                      className="w-4 h-4 ml-1"
                    />
                  </div>
                </div>

                <div className="flex items-start justify-center flex-col profile-info-item">
                  <h5>Availability</h5>
                  <div className="flex items-center">
                    <p>
                      {userDetail?.directorDetails?.availabilityAvg?.toFixed(1)}
                    </p>
                    <img
                      src="/assets/images/hustler-star.png"
                      className="w-4 h-4 ml-1"
                    />
                  </div>
                </div>

                <div className="flex items-start justify-center flex-col profile-info-item">
                  <h5>Payment</h5>
                  <div className="flex items-center">
                    <p>{userDetail?.directorDetails?.paymentAvg?.toFixed(1)}</p>
                    <img
                      src="/assets/images/hustler-star.png"
                      className="w-4 h-4 ml-1"
                    />
                  </div>
                </div>

                <div className="flex items-start justify-center flex-col profile-info-item">
                  <h5>Communication</h5>
                  <div className="flex items-center">
                    <p>
                      {userDetail?.directorDetails?.communicationAvg?.toFixed(
                        1
                      )}
                    </p>
                    <img
                      src="/assets/images/hustler-star.png"
                      className="w-4 h-4 ml-1"
                    />
                  </div>
                </div>
              </div>

              <h4 className="text-md font-medium leading-3">Comments</h4>

              {Comments && Comments.length > 0 ? (
                Comments?.map((item) => (
                  <>
                    <div className="relative flex w-full  flex-col rounded-xl bg-transparent bg-clip-border text-gray-700 shadow-none profile-comments--item">
                      <div className="relative flex items-center gap-4 py-6 px-2 mx-0 overflow-hidden text-gray-700 bg-transparent shadow-none rounded-xl bg-clip-border border bg-white">
                        <img
                          src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${item?.reviewSender?.profilePic}`}
                          alt="Tania Andrew"
                          className="relative inline-block h-[58px] w-[58px] !rounded-full  object-cover object-center"
                        />
                        <div className="flex w-full flex-col gap-0.5">
                          <div className="flex items-center justify-between">
                            <h5 className="block font-sans text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                              {item?.reviewSender?.fname}{" "}
                              {item?.reviewSender?.lname}
                            </h5>
                            <div className="flex items-center gap-0 5">
                              {item?.rating}
                              <img
                                classNameName="ml-2"
                                src="/assets/images/hustler-star.png"
                              />
                            </div>
                          </div>
                          <p className="block font-sans text-base antialiased font-light leading-relaxed text-blue-gray-900">
                            {item?.reviewSender?.email}
                          </p>
                        </div>
                      </div>
                      <div className="p-2 mb-4">
                        <p className="block font-sans text-base antialiased font-light leading-relaxed text-inherit">
                          {item?.comments}
                        </p>
                      </div>
                    </div>
                  </>
                ))
              ) : (
                <p>No comments</p>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="pt-5 px-3">
          <div className="bg-white md:mx-auto rounded shadow-xl w-full md:w-1/2 overflow-hidden">
          <button onClick={handleClickNavigate} className="btn btn-primary w-[20%] p-3 text-left text-white absolute">
			<FaChevronLeft className="mt-1 float-left" />Back </button>

            <div className="h-[100px] bg-gradient-to-r from-[#000000] to-[#585858]"></div>
            <div className="px-5 py-2 flex flex-col gap-3 pb-6">
              <div className="h-[90px] shadow-md w-[90px] rounded-full border-4 overflow-hidden -mt-14 border-white">
                <img
                  src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${userDetail?.profilePic}`}
                  className="w-full h-full rounded-full object-center object-cover"
                />
              </div>
              <div className="">
                <h3 className="text-xl text-slate-900 relative font-bold leading-6 mb-2">
                  {userDetail.fname} {userDetail.lname}
                  <div className="text-sm"><h5>Joining Date: {setReportFormatDate(userDetail.createdAt)}</h5></div>
                </h3>

                {allSerivceCategory &&
                  allSerivceCategory?.serviceName?.map((item) => (
                    <>
                      <span className="bg-black mr-3 text-white rounded-md p-1 ">
                        {" "}
                        {item}
                      </span>
                    </>
                  ))}
              </div>

              <div>
                {allSerivceCategory &&
                  allSerivceCategory?.category?.map((item, index) => (
                    <span className="mr-2">{item}</span>
                  ))}
              </div>

              <p className="text-sm text-stone-500">
                {userDetail?.description}
              </p>

              <div className="profile--info--work">
                <div className="flex items-start justify-center flex-col profile-info-item">
                  <img src="/assets/images/interestshare.png" />
                  <h5>Applied Jobs</h5>
                  <p>{userDetail?.hustlerDetails?.appliedTask}</p>
                </div>
                <div className="flex items-start justify-center flex-col profile-info-item">
                  <img src="/assets/images/completetask.png" />
                  <h5> Completed Jobs </h5>
                  <p>{userDetail?.hustlerDetails?.completedTask}</p>
                </div>
                <div className="flex items-start justify-center flex-col profile-info-item">
                  <img src="/assets/images/pendingtask.png" />
                  <h5> InProgress Jobs</h5>
                  <p>{userDetail?.hustlerDetails?.inProgressTask}</p>
                </div>
                <div className="flex items-start justify-center flex-col profile-info-item">
                  <img src="/assets/images/cancelled.png" />
                  <h5> Cancel Jobs</h5>
                  <p>{userDetail?.hustlerDetails?.cancelledTask}</p>
                </div>

                <div className="flex items-start justify-center flex-col profile-info-item">
                  <h5>Behavior</h5>
                  <div className="flex items-center">
                    <p>{userDetail?.hustlerDetails?.behaviorAvg?.toFixed(1)}</p>
                    <img
                      src="/assets/images/hustler-star.png"
                      className="w-4 h-4 ml-1"
                    />
                  </div>
                </div>

                <div className="flex items-start justify-center flex-col profile-info-item">
                  <h5>Availability</h5>
                  <div className="flex items-center">
                    <p>
                      {userDetail?.hustlerDetails?.availabilityAvg?.toFixed(1)}
                    </p>
                    <img
                      src="/assets/images/hustler-star.png"
                      className="w-4 h-4 ml-1"
                    />
                  </div>
                </div>

                <div className="flex items-start justify-center flex-col profile-info-item">
                  <h5>Skills</h5>
                  <div className="flex items-center">
                    <p>{userDetail?.hustlerDetails?.skillsAvg?.toFixed(1)}</p>
                    <img
                      src="/assets/images/hustler-star.png"
                      className="w-4 h-4 ml-1"
                    />
                  </div>
                </div>

                <div className="flex items-start justify-center flex-col profile-info-item">
                  <h5>Communication</h5>
                  <div className="flex items-center">
                    <p>
                      {userDetail?.hustlerDetails?.communicationAvg?.toFixed(1)}
                    </p>
                    <img
                      src="/assets/images/hustler-star.png"
                      className="w-4 h-4 ml-1"
                    />
                  </div>
                </div>
              </div>

              <h4 className="text-md font-medium leading-3">Review</h4>

              {Comments && Comments.length > 0 ? (
                Comments?.map((item) => (
                  <>
                    <div className="relative flex w-full  flex-col rounded-xl bg-transparent bg-clip-border text-gray-700 shadow-none profile-comments--item">
                      <div className="relative flex items-center gap-4 py-6 px-2 mx-0 overflow-hidden text-gray-700 bg-transparent shadow-none rounded-xl bg-clip-border border bg-white">
                        <img
                          src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${item?.reviewSender?.profilePic}`}
                          alt="Tania Andrew"
                          className="relative inline-block h-[58px] w-[58px] !rounded-full  object-cover object-center"
                        />
                        <div className="flex w-full flex-col gap-0.5">
                          <div className="flex items-center justify-between">
                            <h5 className="block font-sans text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                              {item?.reviewSender?.fname}{" "}
                              {item?.reviewSender?.lname}
                            </h5>
                            <div className="flex items-center gap-0 5">
                              {item?.rating}
                              <img
                                classNameName="ml-2"
                                src="/assets/images/hustler-star.png"
                              />
                            </div>
                          </div>
                          <p className="block font-sans text-base antialiased font-light leading-relaxed text-blue-gray-900">
                            {item?.reviewSender?.email}
                          </p>
                        </div>
                      </div>
                      <div className="p-2 mb-4">
                        <p className="block font-sans text-base antialiased font-light leading-relaxed text-inherit">
                          {item?.comments}
                        </p>
                      </div>
                    </div>
                  </>
                ))
              ) : (
                <p>No reviews</p>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Profile;
