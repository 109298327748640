import moment from "moment";
export function getFormatedDate(date) {
    if(date){
      let formatedDateTime = moment(date).format('MMM Do YYYY');
      return formatedDateTime;
    }
    else {
      return "NA"
    }   
}

export function getDateWithDay(date) {
  if(date){
    let formatedDateTime = moment(date).format('ddd, DD MMMM YYYY hh:mm A');
    return formatedDateTime;
  }
  else {
    return "NA"
  }
}

export const removeDuplicates = (arr) => {
  return [...new Set(arr)];
}

export function setFormatDate(date) {
  if(date){
    let formatedDateTime = moment(date).format('DD/MM/YYYY');
    return formatedDateTime;
  }
   else {
    return "NA"
   }
}

export function setReportFormatDate(date) {
  if (date) {
    let formattedDateTime = moment(date).format('MMM-DD-YY');
    return formattedDateTime;
  } else {
    return "";
  }
}


export function phoneNumberAutoFormat(data){
  const number = data.trim().replace(/[^0-9]/g, "");

  if (number.length < 3) return number;
  if (number.length < 7) return number.replace(/(\d{3})(\d{1})/, "$1-$2");
  if (number.length < 11) return number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
  return number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
}

export function formatTime(timestamp) {
  if (timestamp) {
      let formattedTime = moment(timestamp).format('h:mm A');
      return formattedTime;
  } else {
      return "NA";
  }
}

export const BudgetFormat = (data) => {
  if (data == 0) {
    return "";
  } else if (data.includes(".")) {
    const [integerPart, decimalPart] = data.split('.');
    let modifiedDecimalPart = decimalPart.length === 1 ? decimalPart + '0' : decimalPart;

    if (decimalPart.length === 2) {
      modifiedDecimalPart = decimalPart;
    }

    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Add commas as thousand separators to integer part
    return `${formattedIntegerPart}.${modifiedDecimalPart}`;
  } else if (data.length > 0) {
    const numericValue = data.replace(/[^\d]/g, ""); // Remove non-numeric characters
    const formattedNumericValue = numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Add commas as thousand separators
    return `${formattedNumericValue}.00`; // Append .00 to represent cents
  } else {
    return "";
  }
};

export const payloadBudget = data => {
  const removeData = data.replace(/[a-zA-Z]/g, '');

  const newData = removeData.replace(/,/g, '');
  const newFinalData = newData.replace(/[^0-9.,]/g, '')
  const strData = newFinalData.toString();
  const [integerPart, decimalPart] = strData.split('.');
  if (strData.includes('.')) {
    const strNumber = decimalPart.toString();
    const firstDigit = strNumber[0];
    const secondDigit = strNumber[1];
    const thirdDigit = strNumber[2];

    if (firstDigit && secondDigit) {
      const makeNumber = `${integerPart + '.' + firstDigit + secondDigit}`;
      return makeNumber;
    } else if (firstDigit) {
      const makeNumber = `${integerPart + '.' + firstDigit + '0'}`;
      return makeNumber;
    } else {
      const makeNumber = `${integerPart + '.' + '0' + '0'}`;
      return makeNumber;
    }
  } else {
    const makeNumber = `${integerPart + '.' + '0' + '0'}`;
    return makeNumber;
  }
};

export const displayBudget = data => {
  if (data == 0) {
    return '';
  } else if (data?.includes('.')) {
    const containsAlphabet = /[a-zA-Z]/.test(data);

    if (containsAlphabet) {
      const removeData = data.replace(/[a-zA-Z]/g, '');
      return removeData;
    } else {
      const findIndex = data.indexOf('.');
      const firstnumber = findIndex + 1;
      const secondnumber = findIndex + 3;

      return `${data.slice(0, firstnumber)}${data.slice(
        firstnumber,
        secondnumber,
      )}`;
    }
  } else if (data?.length > 0) {
    
    let numericValue = data?.replace(/[^\d]/g, '');
    
    if (numericValue && parseInt(numericValue, 10) > 819995) {
      
      numericValue = numericValue.slice(0, -1);
    }

    return numericValue
      ? parseInt(numericValue, 10).toLocaleString('en-US')
      : '';
  } else {
    return '';
  }
};