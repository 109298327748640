import React, { useEffect, useState,useRef } from "react";
import { authAxios } from "../config/config";
import { toast } from "react-toastify";
import IsLoadingHOC from "../Common/IsLoadingHOC";
import { Link } from "react-router-dom";
import { FaChevronDown, FaRegEdit } from "react-icons/fa";
import { RiArrowDropDownLine, RiDeleteBinLine } from "react-icons/ri";
import { IoIosMore, IoMdMore } from "react-icons/io";
import { IoSearchOutline } from "react-icons/io5";
import Pagination from "../Common/Pagination";
import CommonModal from "../Common/CommonModal";
import { TbCheck } from "react-icons/tb";
import { GoZoomIn } from "react-icons/go";
import { setFormatDate,setReportFormatDate } from "../Helper/helper";
import { CSVLink } from "react-csv";


const Alluser = (props) => {
  const { setLoading } = props;
  const [Users, setUsers] = useState([]);
  const [activeActionDropdown, setActiveActionDropdown] = useState("");
  const [searchKeywords, setsearchKeywords] = useState("");
  const [checkUser, setcheckUser] = useState("ALL");
  const [currentPage, setcurrentPage] = useState(1);
  const [postsPerPage, setpostsPerPage] = useState(25);
  const [ongoingPagination, setongoingPagination] = useState('all-users')
  const [totalPosts, settotalPosts] = useState(0);
  const [text, settext] = useState("");
  const [actionId, setactionId] = useState("");
  const paginate = (pageNumber) => setcurrentPage(pageNumber);
  const [showModel, setshowModel] = useState(false);
  const [userStatus, setUserStatus] = useState("")
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [csvData, setCsvData] = useState([]); // State for CSV data
  const [isCsvReady, setIsCsvReady] = useState(false); 
  const csvLinkRef = useRef(null);

  // const fetchallUsers = async (userType, number) => {
  //   setLoading(true);
  //   await authAxios()
  //     .get(
  //       `/users/get-all-users/${userType || checkUser}?page=${number || currentPage
  //       }&limit=${postsPerPage}&start_date=${startDate}&end_date=${endDate}`
  //     )
  //     .then((response) => {
  //       const resData = response?.data;
  //       console.log(resData, "reponse data")
  //       settotalPosts(resData.count.total);
  //       setLoading(false);

  //       if (resData?.status == 1) {
  //         setUsers(resData?.data);
  //       } else {
  //         toast.error(resData?.message);
  //       }
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //       toast.error(error?.response?.data?.message);
  //     });
  // };

  const fetchallUsers = async (userType, number) => {
    setLoading(true);
    await authAxios()
      .get(
        `/users/get-all-users/${userType || checkUser}?page=${number || currentPage}&limit=${postsPerPage}&start_date=${startDate}&end_date=${endDate}&query=${searchKeywords}&status=${userStatus}`
      )
      .then((response) => {
        const resData = response?.data;
        console.log(resData, "reponse data")
        settotalPosts(resData.count.total);
        setLoading(false);

        if (resData?.status == 1) {
          setUsers(resData?.data);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };


  
  // const fetchallUsersstatus = async (userType, number) => {
  //   setLoading(true);
  //   await authAxios()
  //     .get(
  //       `adminsearch/search-user?page=${number || currentPage
  //       }&limit=${postsPerPage}&status=${userStatus}&role=${checkUser}&query=${searchKeywords}`
  //     )

  //     .then((response) => {
  //       const resData = response?.data;
  //       console.log(resData, "reponse status data")
  //       settotalPosts(resData.count.total);
  //       setLoading(false);

  //       if (resData?.status == 1) {
  //         if (resData?.data && resData?.data?.length > 0) {
  //           setUsers(resData?.data);
  //         }
  //         else {
  //           // toast.error(resData?.message);
  //           setUsers([]);
  //         }
  //       } else {
  //         toast.error(resData?.message);
  //       }
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //       toast.error(error?.response?.data?.message);
  //     });
  // };



  const handleUserStatus = async (e) => {
    const stat = e.target.value;
    setUserStatus(stat);
    setcurrentPage(1)
  }
  const handleDelete = async (item) => {
    setshowModel(true);
    settext("delete this User");
    setactionId(item?._id);
    if (showModel == true) {
      setLoading(true);
      await authAxios()
        .delete(`/users/delete/${actionId}`)
        .then((response) => {
          const resData = response?.data;
          setLoading(false);
          if (resData?.status == 1) {

            toast.success(resData?.message);
            fetchallUsers(checkUser);
            handleCloseShowModel();
          } else {
            toast.error(resData?.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message);
        });
    }
  };

  const handleCloseShowModel = () => {
    setshowModel(false);
    settext("");
    setactionId("");
    setActiveActionDropdown("");
  };

  const handleSelectChange = (e) => {
    const item = e.target.value;
    setcheckUser(item);
    // setcurrentPage(1);
    fetchallUsers(item, 1);
  };

  const handleActiveActionDropdown = (item) => {
    setActiveActionDropdown((prevItem) => (prevItem === item ? null : item));
  };

  const handleChangeStatus = async (id) => {
    setshowModel(true);
    settext("active this User");
    setactionId(id);
    if (showModel == true) {
      setLoading(true);
      await authAxios()
        .put(`users/change-user-status/${actionId}`)
        .then((response) => {
          const resData = response?.data;
          setLoading(false);
          if (resData?.status == 1) {
            if (resData.data && resData.data.length > 0) {
              fetchallUsers();
              toast.success(resData?.message);
              handleCloseShowModel();
            }
            else {
              // toast.error(resData?.message);
              setUsers([]);
            }
          } else {
            toast.error(resData?.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message);
        });
    }
  };


  const handleSearch = async (value) => {
    if (value) {
      setsearchKeywords(value);
      setongoingPagination("search-users")
      // handleSearching(1, value)
     setcurrentPage(1)

    } else {
      // handleSearching(1, value)
      setongoingPagination("all-users")
      // setcurrentPage(1)
      setsearchKeywords("");
    }
  };

  // const handleSearching = async (number, value) => {
  //   const payload = {
  //     query: value || searchKeywords,
  //   };
  //   await authAxios()
  //     .get(`/adminsearch/search-user?page=${number || currentPage}&limit=${postsPerPage}&role=${checkUser}&status=${userStatus}`, { params: payload })
  //     .then((response) => {
  //       const resData = response?.data;
  //       console.log(resData)
  //       if (resData.status == 1) {
  //         if (resData.data && resData.data.length > 0) {
  //           settotalPosts(resData.count.total)
  //           setUsers(resData.data);
  //         } else {
  //           // toast.info("No matching user found");
  //           setUsers([])
  //         }
  //       } else {
  //         toast.error(resData.error);
  //       }
  //     })
  //     .catch((error) => {
  //       toast.error(error?.response?.data?.message);
  //     });
  // }

  // useEffect(() => {
  //   if (ongoingPagination == "all-users") {
  //     handleSearching()
  //   } else if (ongoingPagination == "search-users") {

  //     handleSearching()
  //   }
  //   else
  //     if (ongoingPagination == "all-users-status")
  //       handleSearching();

  // }, [currentPage, searchKeywords])

  useEffect(() => {
    setongoingPagination("all-users-status")
    // handleSearching();
    fetchallUsers();
  }, [userStatus,searchKeywords])

  
  useEffect(() => {
    fetchallUsers();
  }, [currentPage]);
  useEffect(() => {
    fetchallUsers();
    // setcurrentPage(1)
  }, [startDate,endDate]);

  const handleDownloadCSV = async () => {
    try {
      const response = await authAxios()
      // .get(`/users/get-all-users/ALL?page=&limit=&start_date=${startDate}&end_date=${endDate}`)
      .get(`/users/get-all-users/${checkUser}?page=&limit=&start_date=${startDate}&end_date=${endDate}&query=${searchKeywords}&status=${userStatus}`)
      const resData = response?.data?.data;
      console.log(resData,"resData......")
      const csvFormattedData = resData.map((item, index) => ({
        S_No: index + 1,
        Email: item?.email,
        Name: item==null?"-":(item?.fname+" "+item?.lname),
        Role: item?.userType,
        Status: item?.status,
        Phone_Number:item==null?"-":(item?.phoneno),
        Joining_Date: setReportFormatDate(item?.createdAt)
      }));
      setCsvData(csvFormattedData); 
      setIsCsvReady(true);
      setTimeout(() => {
        if (csvLinkRef.current) {
          csvLinkRef.current.link.click();
          setIsCsvReady(false); 
        }
      }, 0);
      
    } catch (error) {
      toast.error("Failed to download data.");
    }
  };

  return (
    <div className="px-[50px] py-[20px] bg-lightgray area--page pb-0">
      <div className="w-[100%] mx-auto pb-[65px]">
        <div className="flex mb-[30px] gap-[20px] items-center">
          <h1 className="text-[clamp(20px,2vw,35px)] leading-none font-semibold">
            All Users
          </h1>
        </div>
        <div className="flex mb-[30px] gap-[20px] gap-y-[10px] md:items-center w-full md:justify-between md:flex-row flex-col-reverse">
          <div className="w-full">
            <div className="flex flex-row mr-[5px] min-h">
              <div className="relative">
                <select
                  onChange={handleSelectChange}
                  className="px-[20px] h-full rounded-[5px] focus:outline-none appearance-none min-w-[150px] border border-bordergray">
                  <option value="ALL">All Users</option>
                  <option value="HUSTLER">HUSTLER</option>
                  <option value="DIRECTOR">DIRECTORS</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-500">
                  <FaChevronDown />
                </div>
              </div>

              <div className="relative ml-[5px]">
                <select
                  className="px-[20px] border border-white hover:bg-transparent hover:border-textcolor bg-white h-full rounded-[5px] focus:outline-none appearance-none min-w-[150px]"
                  onChange={handleUserStatus}
                >
                  <option value="">Status</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                  <option value="pending">Pending</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-500">
                  <FaChevronDown />
                </div>
              </div>
              <div className="flex gap-[5px] w-full justify-start md:justify-end">
                <button className="flex items-center max-w-[140px] border border-white w-full justify-center bg-white text-textcolor py-[8px] px-[10px] rounded-[5px] duration-[0.3s] hover:bg-transparent hover:border-textcolor hover:text-textcolor h-[45px] font-[600] min-h">
                  Total Users: {totalPosts || 0}
                </button>
              </div>
            </div>

            <div className="flex mt-4 gap-[20px] w-full justify-start">
              <div className="filter--input--search flex w-[315px] items-center bg-white pl-[15px] rounded-[5px] h-[51px] min-h border border-bordergray">
                <img
                  src="/assets/images/search-icon.svg"
                  className="mr-[5px] search--icon"
                />
                <input
                  type="text"
                  placeholder="Search by name, email, phone no"
                  className="input--text max-w-[100%] w-[100%] focus:outline-none"
                  value={searchKeywords}
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </div>
              <div className="float-left bg-white px-2 rounded-lg border border-bordergray">

                <span className="block">Start Date</span>
                <input type="date" value={startDate || ''} onChange={(e) => {
                  setStartDate(e.target.value);
                  setcurrentPage(1)

                }} />
              </div>
              <div className="float-left bg-white px-2 rounded-lg border border-bordergray">

                <span className="block">End Date </span>
                <input type="date" value={endDate || ''} onChange={(e) => {
                  setEndDate(e.target.value);
                  setcurrentPage(1)

                }} />
              </div>

              <button
              onClick={handleDownloadCSV}
              className="flex items-center max-w-[160px] border border-buttonbg w-full justify-center bg-buttonbg text-white py-[8px] px-[15px] h-[39px] rounded-[5px] duration-[0.3s] hover:bg-transparent hover:text-textcolor h-[51px] font-[600] min-h"
              disabled={isCsvReady} // Disable button while fetching data
            >
              {isCsvReady ? "Preparing CSV..." : "Download CSV"}
              
            </button>
            {/* Hidden CSVLink to trigger download */}
            <CSVLink
              data={csvData}
              filename="UsersData.csv"
              className="hidden"
              ref={csvLinkRef}
              target="_blank"
            />
            </div>
          </div>
        </div>

        <div className="relative mt-[10px] overflow-x-auto px-[20px] py-[15px] bg-white rounded-t-[10px] h-[100vh] over--scroller area--table">
          <table className="w-full text-sm text-left text-gray-500 ">
            <thead className="text-xs text-lightcolor border-b border-textcolor">
              <tr>
                <th className="p-4 font-[400]">S.no</th>
                <th className="p-4 font-[400]">Profile Photo</th>
                <th className="p-4 font-[400]">Joining Date</th>
                <th className="p-4 font-[400]">Full Name</th>
                <th className="p-4 font-[400]">Role</th>
                <th className="p-4 font-[400]">Status</th>
                <th className="p-4 font-[400]">Email</th>
                <th className="p-4 font-[400]">Phone</th>
                <th className="p-4 font-[400] w-[50px]">Actions </th>
              </tr>
            </thead>
            <tbody>
              {Users &&
                Users?.length > 0 ?
                Users?.map((item, index) => (
                  <tr
                    key={index}
                    className="bg-white border-b hover:bg-gray-50"
                  >
                    <td className="px-5 py-5 border-b border-gray-200 text-sm max-w-[80px]">
                      <p className="whitespace-no-wrap text-textcolor">
                        {currentPage == 1 ? (index + 1) : ((currentPage - 1) * 25) + (index + 1)}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 text-sm max-w-[120px]">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 w-10 h-10">
                          <Link to={`/profile/${item?._id}`}>
                            <img
                              className="w-full h-full rounded-full"
                              src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${item?.profilePic}`}
                            />
                          </Link>
                        </div>
                      </div>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 text-sm max-w-[150px]">
                      <p className="text-textcolor">
                        {setReportFormatDate(item.createdAt)}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 text-sm max-w-[150px]">
                      <p className="text-textcolor">
                        {item?.fname} {item?.lname}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 text-sm max-w-[100px]">
                      <p className="text-textcolor">{item?.userType}</p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 text-sm max-w-[100px]">
                      <p
                        className={`whitespace-no-wrap font-[700] capitalize ${item?.userType === "HUSTLER"
                          ? item?.status === "active"
                            ? "text-green-500"
                            : item?.status === "inactive"
                              ? "text-red-500"
                              : ""
                          : item?.userType === "DIRECTOR"
                            ? item?.status === "active"
                              ? "text-green-500"
                              : item?.status === "inactive"
                                ? "text-red-500"
                                : ""
                            : ""
                          }`}
                      >
                        {item?.userType === "HUSTLER" ? (
                          <>{item?.status}</>
                        ) : item?.userType === "DIRECTOR" ? (
                          <>{item?.status}</>
                        ) : (
                          <></>
                        )}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 text-sm max-w-[150px]">
                      <p className="text-textcolor">{item.email}</p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 text-sm max-w-[150px]">
                      <p className="text-textcolor">{item?.phoneno}</p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 text-sm max-w-[50px]">
                      <div className="action--btn flex gap-[10px] relative">
                        <div
                          className="more--icon cursor-pointer w-[30px] h-[30px] rounded-[5px] flex items-center justify-center">
                          <Link to={`/profile/${item?._id}`}
                            onClick={() => { localStorage.setItem('AllUserCurrentPage', currentPage); }}><GoZoomIn /></Link>
                        </div>
                        {activeActionDropdown === item && (
                          <div className="action--dropdown">
                            <ul>
                              {item?.hustlerDetails?.userStatus == "inactive" && (
                                <li
                                  onClick={() => handleChangeStatus(item._id)}
                                >
                                  <span> Active</span>
                                </li>
                              )}

                              {item?.directorDetails?.userStatus ==
                                "inactive" && (
                                  <li
                                    onClick={() => handleChangeStatus(item._id)}
                                  >
                                    <span> Active</span>
                                  </li>
                                )}

                              <li>
                                <Link to={`/profile/${item?._id}`}><span>View Details</span></Link>
                              </li>
                              {/* 
                               <li>
                                <span>Frozen</span>
                                {item?.hustlerDetails?.userStatus ==
                                  "frozen" &&
                                  <span><TbCheck className="text-[#008000]" /></span>
                                }
                              </li>
                              <li>
                                <span>Deactive</span>
                                {item?.hustlerDetails?.userStatus ==
                                  "deactive" &&
                                  <span><TbCheck className="text-[#008000]" /></span>
                                }
                              </li>
                              <li>
                                <span>Active</span>
                                {item?.hustlerDetails?.userStatus ==
                                  "active" &&
                                  <span><TbCheck className="text-[#008000]" /></span>
                                }
                              </li>
                              */}
                            </ul>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                )) : (<tr>
                  <td
                    colSpan="9"
                    className="text-center py-5 font-semibold bg-gray-300 text-black"
                  >
                    No data found
                  </td>
                </tr>)}
            </tbody>
          </table>
        </div>
        <Pagination
          currentPage={currentPage}
          totalPosts={totalPosts}
          paginate={paginate}
          postsPerPage={postsPerPage}
          length={Users.length}
        />
        {showModel && (
          <CommonModal
            handleDelete={handleDelete}
            handleCloseShowModel={handleCloseShowModel}
            handleChangeStatus={handleChangeStatus}
            text={text}
          />
        )}
      </div>
    </div>
  );
};

export default IsLoadingHOC(Alluser);
