import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../Components/Home";
import Login from "../Common/Login";
import PrivateRoute from "./PrivateRoute";
import PageNotFound from "../Common/PageNotFound";
import Services from "../Components/Services/Services";
//import ViewServices from "../Components/ViewService";
import AllUser from "../Components/Allusers";
import AllTask from "../Components/Task/AllTask";
import Category from "../Components/Categories/Category";
import CheatWordsList from "../Components/CheatWords/CheatWordsList";
import ViewTask from "../Components/ViewTask";
import Chat from "../Common/Chat";
import Profile from "../Common/Profile";
import AllNotification from "../Components/Notifications/AllNotification";
import PaymentDetail from "../Components/Account/PaymentDetail";
import SubscriptionDetail from "../Components/Account/SubscriptionDetail";
import PaymentInvoice from "../Components/Account/PaymentInvoice";
import SubscriptionInvoice from "../Components/Account/SubscriptionInvoice";
import { useSelector } from "react-redux";
import CommonHome from "../Common/CommonHome";
import PopupNewsLetter from "../Components/NewsLetter/PopupNewsLetter";
import Newsletter from "../Components/NewsLetter/Newsletter";


export default function Routing() {

  const userType = useSelector((state) => state.auth?.user?.userType);
  const { accessToken } = useSelector((state) => state.auth);

  return (
    <Router>
      <Routes>

        {accessToken && userType === "ADMIN" ? (
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />
        ) : (
          <Route
            path="/"
            element={
              <PrivateRoute>
                <CommonHome />
              </PrivateRoute>
            }
          />
        )
        }


        <Route path="/login" element={<Login />} />

        <Route
          path="/all-categories"
          element={
            <PrivateRoute>
              <Category />
            </PrivateRoute>
          }
        />
         <Route
          path="/news-letter"
          element={
            <PrivateRoute>
              {/* <PopupNewsLetter /> */}
              <Newsletter></Newsletter>
            </PrivateRoute>
          }
        />
        <Route
          path="/all-services"
          element={
            <PrivateRoute>
              <Services />
            </PrivateRoute>
          }
        />



        <Route
          path="/sensitive-words"
          element={
            <PrivateRoute>
              <CheatWordsList />
            </PrivateRoute>
          }
        />

        <Route
          path="/view-task/:id"
          element={
            <PrivateRoute>
              <ViewTask />
            </PrivateRoute>
          }
        />

        <Route
          path="/profile/:id"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path="/all-users"
          element={
            <PrivateRoute>
              <AllUser />
            </PrivateRoute>
          }
        />
        <Route
          path="/jobs"
          element={
            <PrivateRoute>
              <AllTask />
            </PrivateRoute>
          }
        />

        <Route
          path="/payment-details"
          element={
            <PrivateRoute>
              <PaymentDetail />
            </PrivateRoute>
          }
        />

        <Route
          path="/payment-invoice"
          element={
            <PrivateRoute>
              <PaymentInvoice />
            </PrivateRoute>
          }
        />

        <Route
          path="/subscription-invoice"
          element={
            <PrivateRoute>
              <SubscriptionInvoice />
            </PrivateRoute>
          }
        />

        <Route
          path="/subscription-details"
          element={
            <PrivateRoute>
              <SubscriptionDetail />
            </PrivateRoute>
          }
        />

        <Route
          path="/chats"
          element={
            <PrivateRoute>
              <Chat />
            </PrivateRoute>
          }
        />

        <Route path="*" element={<PageNotFound />} />

        <Route
          path="/all-notification"
          element={
            <PrivateRoute>
              <AllNotification />
            </PrivateRoute>
          }
        />
        {/*  <Route path="/view-service/:id" element={<PrivateRoute>  <ViewServices /> </PrivateRoute>} />
         */}
      </Routes>
    </Router>
  );
}
